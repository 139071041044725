<template>
   <div class="card mb-1">
      <div class="card-body p-l-10 p-r-10 p-y-10">
         <div class="row align-items-center text-center">
            <div class="col-6 limitador">{{ status.status }}</div>
            <div class="col-6 limitador"><small>R$ </small>{{ parseFloat(status.valor).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'OrderStatus',
   props: ['status', 'index']
}

</script>