<template>
   <div class="card mb-0">
      <div class="card-body p-2">
         <div class="row m-1">
            <div class="w55">
               <div class="ratio ratio-1x1"><img :src="product.icone ? product.icone : ''" @error="imageError" class="rounded" /></div>
            </div>
            <div class="col pl-3">
               <h1 class="limitador weight-600 font-15 mb-2">{{ product.nome }}</h1>
               <p class="limitador font-13 mb-0"><small>R$ </small>{{ parseFloat(product.valor).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</p>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'ProductSale',
   props: ['product', 'index'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>