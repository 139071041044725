<template>
	<div class="mb-5">
		<div class="card mb-0">
			<div class="tp-hero__area pt-100 pb-80 p-relative p-5">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-xl-6 col-lg-6">
							<h3 class="tp-hero__title-lg pb-20 color-theme">{{ $t("dashboard.titlePage") }}</h3>
							<p class="pb-25">{{ $t("dashboard.subtitles.subTitle1") }}</p>
							<p class="pb-25">{{ $t("dashboard.subtitles.subTitle2") }}</p>
							<router-link to="/Premium">
								<button class="button_donate"><i class="far fa-star font-16 mr-2"></i> {{ $t("navBar.subscription") }}</button>
							</router-link>
						</div>
						<div class="col-xl-6 col-lg-6 pl-5">
							<img class="w-100" src="@/assets/images/dashboard/guarda-comp.png" alt="Start image">
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<hr>

		<div class="card mb-0" v-if="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic'">
			<div class="card-body p-3">
				<div class="row">
					<div class="col-12">
						<h1 class="limitador font-17 mb-0"><i class="fal fa-shopping-cart font-16 color-theme mr-2"></i><span> {{ $t("dashboard.ecommerceStatistics") }}</span></h1>
					</div>
					<div class="col-12 m-t-100 m-b-100 text-center font-15">
						<span class="d-block mb-1">{{ $t("vipMessage") }}</span>
						<span class="d-block mb-4"><i class="far fa-star color-theme font-13 mr-2"></i>Standard</span>
						<router-link to="/Premium" class="btn btn-primary btn-lg font-16"><i class="far fa-long-arrow-right font-14 mr-3"></i>{{ $t("premium.seePlans") }}</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-12">
				<div class="card mb-2">
					<div class="card-body p-3">
						<h1 class="limitador font-17 mb-0"><i class="fal fa-shopping-cart font-16 color-theme mr-2"></i><span> {{ $t("dashboard.ecommerceStatistics") }}</span></h1>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-xl-5">
				<div class="card h-100 mb-0">
					<div class="card-body py-3">
						<div class="row">
							<div class="col-12 mt-1 text-center weight-600 text-uppercase font-17 color-theme limitador">{{ $t("dashboard.salesLastDays") }}</div>
							<div class="col-12"><hr></div>
							<div class="col-12 m-t-100 m-b-100 text-center font-15" v-if="dashboard.salesLastDays.length == 0">{{ $t("dashboard.noOrdersFound") }}</div>
							<div class="col-12" v-else>
								<router-link class="row" :to="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce) ? '/ecommerceSales' : ''">
									<div class="col-6 pl-3 mb-3 text-center weight-600 text-uppercase font-14 color-theme"><i class="far fa-stream font-12 mr-2"></i>Status</div>
									<div class="col-6 pr-3 mb-3 text-center weight-600 text-uppercase font-14 color-theme"><i class="far fa-money-bill-wave-alt font-12 mr-2"></i>{{ $t("dashboard.value") }}</div>
		
									<orderStatus v-for="(status, index) in dashboard.salesLastDays" :key="index" :status="status" :index="index" class="py-12" />
		
									<div class="col-6 pl-3 mt-12 text-center weight-600 text-uppercase font-15 color-theme">Total</div>
									<div class="col-6 pr-3 mt-12 text-center weight-600 text-uppercase font-15 color-theme"><small>R$ </small>{{ dashboard.salesLastDays.reduce((total, p) => total + parseFloat(p.valor), 0).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-xl-4 mt-2 mt-md-0">
				<div class="card h-100 mb-0">
					<div class="card-body pt-3 pb-12">
						<div class="row">
							<div class="col-12 mt-1 mb-3 text-center weight-600 text-uppercase font-17 color-theme limitador">{{ $t("dashboard.bestSellingProductsLastDays") }}</div>
							<div class="col-12 m-t-100 m-b-100 text-center font-15" v-if="dashboard.productsSalesLastDays.length == 0">{{ $t("dashboard.noSalesFound") }}</div>
							<router-link class="col-12" v-else :to="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce) ? '/ecommerceProducts' : ''">
								<product v-for="(product, index) in dashboard.productsSalesLastDays" :key="index" :product="product" :index="index" class="mb-1" />
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 mt-2 mt-xl-0">
				<router-link class="card mb-2" :to="dadosUsuario.id == serverS.id_dono || serverS.isAdmin ? '/ecommerceSettings' : ''">
					<div class="card-body d-flex align-items-center p-3">
						<img :src="dashboard.shopLogo ? dashboard.shopLogo : ''" @error="imageError" class="rounded mr-4" height="50" />
						<h1 class="limitador font-19 mb-0">{{ dashboard.shopName }}</h1>
					</div>
				</router-link>
				<a class="card mb-2" :href="urlEcommerce + dashboard.shopUrl" target="_blank">
					<div class="card-body d-flex align-items-center">
						<i class="fal fa-external-link-alt font-40 color-theme mr-4"></i>
						<h1 class="limitador font-19 mb-0">{{ $t("dashboard.visitStore") }}</h1>
					</div>
				</a>
				<router-link class="card mb-2" :to="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce) ? '/ecommerceProducts' : ''">
					<div class="card-body d-flex align-items-center">
						<i class="fal fa-shopping-bag font-40 color-theme"></i>
						<div class="ml-4">
							<h1 class="limitador font-19 mb-1">{{ dashboard.amountProducts }} <span class="ml-2">{{ $t("dashboard.products") }}</span></h1>
							<p class="limitador font-13 mb-0">{{ $t("dashboard.productsDesc") }}</p>
						</div>
					</div>
				</router-link>
				<router-link class="card mb-2" :to="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce) ? '/ecommerceMonitoring' : ''">
					<div class="card-body d-flex align-items-center">
						<i class="fal fa-user font-40 color-theme"></i>
						<div class="ml-4">
							<h1 class="limitador font-19 mb-1">{{ dashboard.amountUsers }} <span class="ml-2">{{ $t("dashboard.users") }}</span></h1>
							<p class="limitador font-13 mb-0">{{ $t("dashboard.usersDesc") }}</p>
						</div>
					</div>
				</router-link>
				<router-link class="card mb-0" :to="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionTicket) ? '/ticketSearch' : ''">
					<div class="card-body d-flex align-items-center">
						<i class="fal fa-receipt font-40 color-theme"></i>
						<div class="ml-4">
							<h1 class="limitador font-19 mb-1">{{ dashboard.amountOpenTickets }} <span class="ml-2">{{ $t("dashboard.openTickets") }}</span></h1>
							<p class="limitador font-13 mb-0">{{ $t("dashboard.openTicketsDesc") }}</p>
						</div>
					</div>
				</router-link>
			</div>
		</div>

		<hr v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionWl) || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce) || serverS.roles.some(r => r.id == serverS.idPermissionTicket)">

		<div class="cards-1">
			<router-link to="/Welcome_&_Goodbye" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/welcome-icon2.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.welcome.title") }}</p>
					<p class="text_description">{{ $t("dashboard.welcome.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/GeneralCommands" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/list-commands2.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.commands.title") }}</p>
					<p class="text_description">{{ $t("dashboard.commands.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/ComandosCustom" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/custom-comands2.png">
					</div>
					<p class="text_title">{{ $t("dashboard.custom.title") }}</p>
					<p class="text_description">{{ $t("dashboard.custom.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/Giveaway" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/list-giveway2.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.giveaway.title") }}</p>
					<p class="text_description">{{ $t("dashboard.giveaway.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/Polls" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/list-pool2.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.pools.title") }}</p>
					<p class="text_description">{{ $t("dashboard.pools.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/ticketSettings" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/ticket-config2.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.ticketSettings.title") }}</p>
					<p class="text_description">{{ $t("dashboard.ticketSettings.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/ticketSearch" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionTicket)">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/ticket-serach2.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.ticketSearch.title") }}</p>
					<p class="text_description">{{ $t("dashboard.ticketSearch.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/whitelistSettings" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/list-settings2.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.wlStg.title") }}</p>
					<p class="text_description">{{ $t("dashboard.wlStg.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/whitelistQuestions" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionWl)">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/list-questions2.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.wlQts.title") }}</p>
					<p class="text_description">{{ $t("dashboard.wlQts.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/whitelistCompletedForms" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionWl)">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/list-check2.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.wlCfs.title") }}</p>
					<p class="text_description">{{ $t("dashboard.wlCfs.desc") }}</p>
				</div>
			</router-link>
			<router-link to="/ecommerceSettings" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/ecommerceSettings.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.ecommerce.settings") }}</p>
					<p class="text_description">{{ $t("dashboard.ecommerce.settingsDesc") }}</p>
				</div>
			</router-link>
			<router-link to="/ecommerceProducts" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce)">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/ecommerceProducts.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.ecommerce.products") }}</p>
					<p class="text_description">{{ $t("dashboard.ecommerce.productsDesc") }}</p>
				</div>
			</router-link>
			<router-link to="/ecommerceSales" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce)">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/ecommerceSales.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.ecommerce.sales") }}</p>
					<p class="text_description">{{ $t("dashboard.ecommerce.salesDesc") }}</p>
				</div>
			</router-link>
			<router-link to="/ecommerceMonitoring" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce)">
				<div class="card_path wallet">
					<div class="overlay_card"></div>
					<div class="circle">
						<img src="@/assets/images/dashboard/ecommerceMonitoring.png" >
					</div>
					<p class="text_title">{{ $t("dashboard.ecommerce.monitoring") }}</p>
					<p class="text_description">{{ $t("dashboard.ecommerce.monitoringDesc") }}</p>
				</div>
			</router-link>
		</div>
		
		<hr>

		<div class="card mb-2">
			<div class="tp-hero__area p-relative p-3">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-xl-6 col-lg-6">
							<div class="tp-about__section-box">
								<h3 class="tp-hero__title-lg color-theme mb-4">{{ $t("dashboard.landingCards.welcome.title") }}</h3>
								<div class="tp-about__text pt-4">
									<p class="aboutContent">{{ $t("dashboard.landingCards.welcome.desc") }}</p>
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-lg-6">
							<div class="tp-about__thumb-wrapper p-relative">
								<div class="tp-about__thumb">
									<img class="w-100" src="@/assets/images/dashboard/desck-banner-3.png" alt="about us">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card mb-2">
			<div class="tp-hero__area p-relative p-3">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-xl-6 col-lg-6">
							<div class="tp-about__thumb-wrapper p-relative">
								<div class="tp-about__thumb">
									<img class="w-100" src="@/assets/images/dashboard/desck-banner-1.png" alt="about us">
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-lg-6">
							<div class="tp-about__section-box">
								<h3 class="tp-hero__title-lg color-theme mb-4">{{ $t("dashboard.landingCards.ticket.title") }}</h3>
								<div class="tp-about__text pt-4">
									<p class="aboutContent">{{ $t("dashboard.landingCards.ticket.desc") }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card mb-2">
			<div class="tp-hero__area p-relative p-3">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-xl-6 col-lg-6">
							<div class="tp-about__section-box">
								<h3 class="tp-hero__title-lg color-theme mb-4">{{ $t("dashboard.landingCards.wl.title") }}</h3>
								<div class="tp-about__text pt-4">
									<p class="aboutContent">{{ $t("dashboard.landingCards.wl.desc") }}</p>
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-lg-6">
							<div class="tp-about__thumb-wrapper p-relative">
								<div class="tp-about__thumb">
									<img class="w-100" src="@/assets/images/dashboard/desck-banner-2.png" alt="about us">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import product from '@/components/ecommerce/ProductSale.vue'
import orderStatus from '@/components/ecommerce/OrderStatus.vue'

export default {
	name: 'Dashboard',
	data: function () {
		return {
			dashboard: {'shopLogo': null, 'shopName': null, 'shopUrl': null, 'amountProducts': 0, 'amountUsers': 0, 'amountOpenTickets': 0, 'salesLastDays': [], 'productsSalesLastDays': []}
		}
	},
	computed: {
		...mapState({
			urlEcommerce: state => state.urlEcommerce,
			dadosUsuario: state => state.dadosUsuario,
			serverS: state => state.serverS,
			urlRest: state => state.urlRest
		})
	},
	components: {
		orderStatus, product
	},
	methods: {
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		getDashboard : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getDashboard',
				params: {
					idServer: this.serverS.id_s
				}
         }).then((response) => {
            this.dashboard = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
		}
	},
	mounted() {
		this.getDashboard()
	}
}

</script>

<style scoped>

.tp-about__text {
	border-top: 2px solid rgb(255, 255, 255);
	padding-top: 10px;
}

.tp-hero__title-lg {
	font-weight: 600;
	font-size: 58px;
	color: #fff;
	line-height: 1.2;
}

.tp-hero__wrapper-3 {
	height: 400px;
	width: 450px;
}

.wallet {
	--bg-color: #FF7321;
	--bg-color-light: #222427;
	--text-color-hover: #fff;
	--box-shadow-color: rgba(255, 255, 255, 0.48);
}

/* Cards */
.card_path {
	margin-right: 5px;
	margin-top: 5px;
	height: 250px;
	background: #222427;
	border-radius: 10px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	transition: all 0.3s ease-out;
	text-decoration: none;
}

.card_path:hover {
	transform: translateY(-5px) scale(1.005) translateZ(0);
	/* box-shadow: 0px 0px 10px 2px rgb(255, 102, 0); */
	cursor: pointer;
}

.card_path:hover .overlay_card {
	transform: scale(6) translateZ(0);
}

.card_path:hover .circle {
	border-color: var(--bg-color-light);
	background: var(--bg-color);
}

.card_path:hover .circle:after {
	background: var(--bg-color-light);
}

.text_description {
	display: flex;
	text-align: center;
	color: #ffffff;
	z-index: 5;
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 3px;
	margin-bottom: 0px;
	font-size: 13px;
	font-style: italic;
}


.card_path:hover .text_description {
	color: var(--text-color-hover);
}

.card_path .text_title {
	font-size: 17px;
	color: #ffffff;
	margin-bottom: 0px;
	margin-top: 10px;
	z-index: 5;
	transition: color 0.3s ease-out;
	font-weight: bold;
}

.circle {
	width: 131px;
	height: 131px;
	border-radius: 50%;
	background: #222427;
	border: 2px solid var(--bg-color);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 2;
	transition: all 0.3s ease-out;
	padding: 35px;
}

.circle:after {
	content: "";
	width: 118px;
	height: 118px;
	display: block;
	position: absolute;
	background: var(--bg-color);
	border-radius: 50%;
	transition: opacity 0.3s ease-out;
}

.overlay_card {
	margin-bottom: 70px;
	width: 100px;
	position: absolute;
	height: 100px;
	border-radius: 50%;
	background: var(--bg-color);
	z-index: 1;
	transition: transform 0.3s ease-out;
}

.circle img {
	height: 100%;
	width: 100%;
	z-index: 10;
}

/* ButtonDonate */
.button_donate {
	--color: #FF7321;
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px;
	background-color: transparent;
	border-radius: .3em;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	transition: .5s;
	font-weight: 600;
	font-size: 20px;
	border: 1px dashed;
	font-family: inherit;
	text-transform: uppercase;
	color: var(--color);
	z-index: 1;
}

.button_donate::before, .button_donate::after {
	content: '';
	display: block;
	width: 50px;
	height: 50px;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 50%;
	z-index: -1;
	background-color: var(--color);
	transition: .5s ease;
}

.button_donate::before {
	top: -1em;
	left: -1em;
}

.button_donate::after {
	left: calc(100% + 1em);
	top: calc(100% + 1em);
}

.button_donate:hover::before, .button_donate:hover::after {
	height: 410px;
	width: 410px;
}

.button_donate:hover {
	color: rgb(255, 255, 255);
}

.button_donate:active {
	filter: brightness(.8);
}

</style>